define(['app'], function (app) {
  

  var productInformation = function () {
    var component = {};

    var _config = {
      selectors: {
        informationType: 'data-information-component',
        component: {
          ingredients: '[data-information-component=ingredients]',
          nutritionalInformation: '[data-information-component=nutritionalInformation]',
          suggestedUse: '[data-information-component=suggestedUse]'
        },
        ingredients: '[data-product-ingredients]',
        nutritionalInformation: '[data-product-nutritional-information]',
        suggestedUse: '[data-product-suggested-use]',
        loading: '[data-loading]'
      },
      subscribeChannel: {
        information: 'productInformation/newInformation'
      },
      classes: {
        loadingShow: 'productInformation_loading-show'
      }
    };

    var _init = function (element) {
      component.element = element;
      component.type = component.element.getAttribute(component.config.selectors.informationType);
      component.loading = component.element.querySelector(component.config.selectors.loading);
      var informationSubscribe = app.subscribe(component.config.subscribeChannel.information, component.getNewInformation);
      component.checkSubscribeMessages(informationSubscribe, component.getNewInformation);

      return component;
    };

    var _getNewInformation = function(productId) {
      component.showLoading();

      app.ajax.get({
        url: '/' + productId + '.' + component.type,
        success: component.informationSuccessHandler,
        error: component.informationErrorHandler
      });
    };

    var _informationSuccessHandler = function(response) {
      component.informationReceived(response);
      component.hideLoading();
    };

    var _informationErrorHandler = function() {
      component.hideLoading();
      console.error('ERROR: Could not retrieve child product information');
    };

    var _informationReceived = function(information) {
      var newContainer = document.createElement('div');
      newContainer.innerHTML = information;
      var newElem = newContainer.querySelector(component.config.selectors.component[component.type]);

      if (!newElem){
        return;
      }

      var newInformation = newElem.querySelector(component.config.selectors[component.type]);
      var oldInformationOccurrences = document.querySelectorAll(component.config.selectors[component.type]);

      if (newInformation) {
        for(var i = 0; i < oldInformationOccurrences.length; i++) {
          var item = newInformation.cloneNode(true);
          var parent = oldInformationOccurrences[i].parentNode;
          parent.removeChild(oldInformationOccurrences[i]);
          parent.appendChild(item);
        }
      }
    };

    var _checkSubscribeMessages = function(subscription, callback) {
      if (subscription.messages.length > 0) {
        var lastItem = subscription.messages.slice(-1)[0];
        callback(lastItem);
      }
    };

    var _showLoading = function() {
      app.element.addClass(component.config.classes.loadingShow, component.loading);
    };

    var _hideLoading = function() {
      app.element.removeClass(component.config.classes.loadingShow, component.loading);
    };

    component.init = _init;
    component.config = _config;
    component.getNewInformation = _getNewInformation;
    component.informationSuccessHandler = _informationSuccessHandler;
    component.informationErrorHandler = _informationErrorHandler;
    component.informationReceived = _informationReceived;
    component.checkSubscribeMessages = _checkSubscribeMessages;
    component.showLoading = _showLoading;
    component.hideLoading = _hideLoading;

    return component;
  };

  return productInformation;
});
